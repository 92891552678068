import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Components/Screen/Home'
import CowProduct from '../Components/Screen/CowProduct'
import BuffeloProduct from '../Components/Screen/BuffeloProduct'
import Gallary from '../Components/Screen/Gallary'

const Webroutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/cow-ghee" element={<CowProduct />} />
      <Route path="/buffelo-ghee" element={<BuffeloProduct />} />
      <Route path="/gallary" element={<Gallary />} />
    </Routes>
  )
}  

export default Webroutes
