import React, { useState } from 'react'
import CommomProductComponent from './CommomProductComponent'
import GC1 from '../assets/images/Product/Cow/1C.png'
import GC2 from '../assets/images/Product/Cow/5PC.png'
import GC3 from '../assets/images/Product/GC3.png'
import BC1 from '../assets/images/Product/Buffelo/1B.png'
import BC5 from '../assets/images/Product/Buffelo/5B.png'
import BC15 from '../assets/images/Product/Buffelo/15B.png'
const ProductHome = () => {
  const [cowGhee,] = useState([
    {
      img: GC1,
      weight: '1 Ltr'
    },
    {
      img: GC2,
      weight: '5 Ltr'
    },
    {
      img: GC3,
      weight: '15 kg'
    },
  ])
  const [buffeloGhee,] = useState([
    {
      img: BC1,
      weight: '1 Ltr'
    },
    {
      img: BC5,
      weight: '5 Ltr'
    },
    {
      img: BC15,
      weight: '15 kg'
    },
  ])
  return (
    <div className='waveBg'>
      <div className='m-auto flex max-w-[1240px] items-center justify-center max_xl:max-w-[940px] 
      max_md:px-[50px] max_sm:px-[20px] h-[100%]'>

        <div className='flex flex-col w-full py-[50px]'>
          <CommomProductComponent heading="Classic cow ghee" path="/cow-ghee"
            subHeading="Introducing our classic cow ghee products made with real cow milk"
            GheeData={cowGhee} viewAll={true} />
          <CommomProductComponent heading="Premium buffalo ghee" path="/buffelo-ghee"
            subHeading="Introducing our premium buffalo ghee products made with real buffalo milk"
            GheeData={buffeloGhee} viewAll={true} />     

        </div>
      </div>
    </div>
  )
}

export default ProductHome
