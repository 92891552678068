import React from 'react'
import GheeMaking from '../assets/images/Home/GheeMaking.png'
import boiling from '../assets/images/Home/Boiling.jpg'
import separating from '../assets/images/Home/Separating.jpg'
import heating from '../assets/images/Home/Heating.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import './CSS/SwiperCss.css'

const GheeMakingProcess = () => {
  
  const makingProcess = [boiling, separating, heating]
  return (
    <>
      <div className='m-auto max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]'>
        <div className='F-JC-AI-CENTER headingT uppercase my-[30px] max_sm:text-[30px] max_sm:text-center'>YOGIC Ghee Making Process</div>
      </div>
      <div className='h-[2px] w-full border-t-[2px] border-dashed border-Lightyellow'></div>
      <div className='m-auto max-w-[1240px] max_xl:max-w-[940px] my-[20px] max_md:px-[50px] max_sm:px-[20px] max_sm:hidden'>
        <div className='F-JC-AI-CENTER'>
          <img src={GheeMaking} alt='Ghee_making' />
        </div>
      </div>
    

      {/* Mobile view */}

      <div className='m-auto max-w-[1240px] max_xl:max-w-[940px] my-[20px] max_md:px-[50px] max_sm:px-[20px] hidden max_sm:block'>
        <div className='flex'>
          <Swiper pagination={true} modules={[Pagination]}  
          style={{"--swiper-pagination-color": "#FFBA08",overflow:'visible',spaceBetween: 30,
        
        }}
          className="mySwiper w-[100%] h-[100%]">
            {makingProcess.map((img, index) => {
              return (
                <React.Fragment key={index+1}>
                  <SwiperSlide>
                    <div >
                      <img src={img} alt='ghee_making' />
                    </div>

                  </SwiperSlide>

                </React.Fragment>)
            })}
          </Swiper>
        </div>
      </div>
      <div className='h-[2px] w-full border-t-[2px] border-dashed border-Lightyellow max_sm:mt-8'></div>
      {/* <div className='m-auto max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]'>
        <div className='F-JC-AI-CENTER headingT uppercase mt-[60px] max_sm:text-center'>
          Video to help you
        </div>
        <div className='w-full border-[4px] rounded-[30px] h-[580px] border-lightPink my-[30px]'>

        </div>
      </div> */}

    </>
  )
}

export default GheeMakingProcess
