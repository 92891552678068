import React, { useState } from 'react'
import Logo from "../assets/images/Navbar/YogicLogoFooter.svg"
import Call from "../assets/images/Footer/Call.svg"
import Email from "../assets/images/Footer/Email.svg"
import Location from "../assets/images/Footer/Location.svg"
import Insta from "../assets/images/Footer/Insta.svg"
import facebook from "../assets/images/Footer/facebook icon.png"
import Youtube from "../assets/images/Footer/Youtube.svg"
import Whatsapp from "../assets/images/Footer/Whatsapp.svg"

import { useNavigate } from 'react-router-dom'
import "../CSS/Home.css"

const Footer = () => {
  const [navLink,] = useState([
    {
      name: 'Home'
    },
    {
      name: 'Product'
    },
    {
      name: 'About us'
    },
    {
      name: 'Contact us'
    }
  ])
  const navigate = useNavigate()
  const scrollToSection = (id) => {
    var element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <>
      <div className='pt-44 max_xl:pt-40 max_768:pt-36 max_sm:pt-5'>

        <div className='m-auto max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]'>
          <div className='flex justify-between w-full mb-[4%] max_720:flex-col max_720:gap-[30px] max_sm:gap-[20px]
          max_sm:items-center'>
            <div className='w-[30%] max_sm:w-[60%]'>
              <img src={Logo} alt='Logo' />
            </div>

            <div className='flex w-[55%] max_md:w-[65%] max_720:w-[100%] justify-between max_sm:flex-col max_sm:gap-5 max_sm:mt-2'>
              <div className='flex flex-col gap-5 max_sm:gap-2 max_md:gap-3 max_sm:flex-row max_sm:flex-wrap max_sm:justify-center'>
                {navLink.map((item) => {
                  return (
                    <>
                      <div className='MediumT max_md:w-[40%] cursor-pointer' onClick={() => {
                        if (item.name === "Home") {
                          navigate('/')
                        }
                        else if (item.name === "Product") {
                          navigate('/')
                          scrollToSection('Prod')
                        }
                        else if (item.name === "About us") {
                          navigate('/')
                          setTimeout(() => {
                            scrollToSection("about_ghee")
                          }, 500)
                        } else {
                          navigate('/')
                          setTimeout(() => {
                            scrollToSection("contact_us")
                          }, 500)
                        }
                      }}>{item.name}</div>
                    </>
                  )
                })}
              </div>
              <div className='flex flex-col gap-5 max_sm:items-center max_sm:gap-2'>
                <div className='flex flex-col gap-3 max_sm:gap-1'>
                  <div className='flex gap-2 items-center MediumT'>
                    <div>
                      <img src={Call} alt='Call' />
                    </div>
                    <a href="tel:+91 9687270600">
                      <div>+91 9687270600</div>
                    </a>
                  </div>
                  <div className='flex gap-2 items-center MediumT'>
                    <div>
                      <img src={Email} alt='Call' />
                    </div>
                    <a href="mailto:yogicdesimart@gmail.com"
                      onClick={() => "window.location=another.html"}
                    >
                      <div>yogicdesimart@gmail.com</div>
                    </a>
                  </div>
                  <div className='flex gap-2 items-center MediumT'>
                    <div>
                      <img src={Location} alt='Call' />
                    </div>
                    <div>Village - Vav, Kamrej, Surat, Gujarat.</div>
                  </div>
                </div>
                <div className='flex gap-[10%] '>
                  <div>
                    <a
                      href="https://wa.me/9687270600"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img src={Whatsapp} alt='Whatsapp' className='cursor-pointer' />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://instagram.com/yogicghee?igshid=OGQ5ZDc2ODk2ZA=="
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img src={Insta} alt='Insta' className='cursor-pointer' />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.facebook.com/profile.php?id=100090600758395&mibextid=ZbWKwL"
                      rel="noopener noreferrer"
                      target="_blank"
                    >

                      <img src={facebook} alt='facebook' className='cursor-pointer' />
                    </a>
                  </div>
                  <div>
                    <img src={Youtube} alt='Youtube' className='cursor-pointer' />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className='h-[2px] w-full border-t-[2px] border-dashed border-Lightyellow'></div>

        <div className='F-JC-AI-CENTER text-[18px] font-PoppinsRegular text-blackText py-[30px] max_768:py-[20px] max_sm:py-[10px] max_sm:text-[14px] max_sm:text-center'>2023@Yogicghee.com. All Right Reserved | Designed by Chit Design</div>
      </div>
    </>
  )
}

export default Footer
