import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar'
import '../../CSS/Home.css'
import Footer from '../Footer'
import { CSSTransition } from "react-transition-group";
import MobileMenu from '../MobileMenu';
import { useOnClickOutside } from './OutsideClick';


const Layout = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navbarChangeColor, setNavbarChangeColor] = useState(false);
  const nodeRef = useRef(null);
  const outsideRef = useRef(null);
  
  useOnClickOutside(outsideRef, () => setIsMobileMenuOpen(false));
  useEffect(() => {
    const body = document.querySelector("body");
    if (isMobileMenuOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [isMobileMenuOpen]);

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbarChangeColor(true);
    } else {
      setNavbarChangeColor(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", changeBackground);
      // cleanup function
      return () => {
        window.removeEventListener("scroll", changeBackground);
      };
    }
  });

  return (
    <div className='relative w-full'>
      <div className={`fixed top-0 w-full z-40 max_sm:z-40 ${navbarChangeColor ? 'bg-[#FFeFA9] shadow-sm' : 'bgNavbar'}`}>
        <Navbar setIsMobileMenuOpen={setIsMobileMenuOpen} />
      </div>
      <CSSTransition
        in={isMobileMenuOpen}
        nodeRef={nodeRef}
        timeout={500}
        classNames="menu"
        type={"out-in" | "in-out"}
        unmountOnExit
        onEnter={() => setIsMobileMenuOpen(true)}
        onExited={() => setIsMobileMenuOpen(false)}
      >
        <MobileMenu
          nodeRef={nodeRef}
          outsideRef={outsideRef}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />
      </CSSTransition>
      <div className="overflow-x-hidden mt-[80px] max_sm:mt-[70px]">{children}</div>

      <div className='footerBg'>
        <Footer />
      </div>

    </div>
  )
}

export default Layout
