import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import "./CSS/SwiperCss.css"
import { useNavigate } from 'react-router-dom';


const CommomProductComponent = ({ heading, subHeading, GheeData, viewAll,path }) => {
  const navigate = useNavigate()
  return (
    <div className='w-full flex flex-col py-[40px]'>
      <div className='headingT max_md:F-JC-AI-CENTER max_sm:text-[24px] max_md:text-[30px] max_sm:text-center 
      uppercase'>{heading}</div>
      <div className='flex justify-between items-center font-PoppinsRegular mb-[30px] max_md:justify-center'>
        <div className='text-[20px] text-blackText max_md:text-center max_sm:text-[16px]'>{subHeading} </div>
        {viewAll && (
          <>
            <div className='F-JC-AI-CENTER py-[10px] bg-Lightyellow rounded-[5px] text-white 
        text-[20px] w-[135px] max_md:hidden cursor-pointer' onClick={()=>navigate(path)}>View All</div>
          </>
        )}
      </div>

      <div className='w-full flex flex-wrap gap-[30px] max_xl:gap-[20px] max_md:justify-center 
      max_768:hidden'>
        {GheeData?.map((product, index) => {
          return (
            <React.Fragment key={index+1}>
              <div className='m-auto flex items-center justify-end w-[31%] h-[405px] max_xl:h-[375px] 
              flex-col bg-white 
              rounded-[10px] border-[1px] border-Lightyellow' >   
                <div className='py-[10px]'>
                  <img src={product.img} alt='GC1' className='transition duration-300 ease-in-out hover:scale-105' />
                </div>
                <div className='text-[28px] mb-[10px] font-PoppinMedium'>{product.weight}</div>
              </div>
            </React.Fragment>
          )
        })}

      </div>


      {/* Tablet design */}
      <div className='w-full flex justify-center 
      max_sm:hidden max_768:block max max_xl:hidden max_md:hidden'>

        <div className='h-[370px] max_768:block hidden w-[50%] max_z:h-[320px]'>
          <Swiper modules={[Pagination]} className="mySwiper h-[100%] 
          swiper-slide-gap" 
          style={{"--swiper-pagination-color": "#FFBA08",overflow:'visible',"swiper-pagination-bullet":'#FFCD9F'}}>
            {GheeData?.map((product, index1) => {
              return (
                <React.Fragment key={index1+1}>
                  <SwiperSlide style={{display:'flex',justifyContent:'center'}}>
                    <div className='flex items-center justify-end w-[27%] min-w-[290px] 
                    h-[100%] flex-col bg-white max_z:min-w-[254px]
                      rounded-[10px] border-[1px] border-Lightyellow mx-[40px]' >
                      <div className='py-[10px] max_z:flex max_z:justify-center max_z:items-end'>
                        <img src={product.img} alt='GC1' className='w-[100%] h-[100%] 
                        max_z:h-[85%]' />
                      </div>
                      <div className='text-[28px] mb-[10px] font-PoppinMedium'>{product.weight}</div>
                    </div>

                  </SwiperSlide>

                </React.Fragment>
              )
            })}
          </Swiper>
        </div>
      </div>
      {/* mobile design  */}
      <div className='w-full flex justify-center max_sm:block max_xl:hidden max_md:hidden  
      max_720:hidden'>

        <div className='h-[370px] max_sm:block hidden w-[100%] max_z:h-[320px]'>
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper h-[100%] 
          swiper-slide-gap" 
          style={{"--swiper-pagination-color": "#FFBA08",overflow:'visible'}}>
            {GheeData?.map((product, index2) => {
              return (
                <React.Fragment key={index2+1}>
                  <SwiperSlide style={{display:'flex',justifyContent:'center'}}>
                    <div className='flex items-center justify-end w-[27%] min-w-[290px] max_es:min-w-[220px]
                    h-[100%] flex-col bg-white max_z:min-w-[254px] max_es:h-[95%]
                      rounded-[10px] border-[1px] border-Lightyellow mx-[40px]' >
                      <div className='py-[10px] max_z:flex max_z:justify-center max_z:items-end'>
                        <img src={product.img} alt='GC1' className='w-[100%] h-[100%] max_z:h-[85%] max_es:h-[75%]' />
                      </div>
                      <div className='text-[28px] mb-[10px] font-PoppinMedium'>{product.weight}</div>
                    </div>

                  </SwiperSlide>

                </React.Fragment>
              )
            })}
          </Swiper>
        </div>
      </div>
      <div className="F-JC-AI-CENTER mt-[30px]">
        {viewAll && (
          <>
            <div className='F-JC-AI-CENTER py-[10px] px-[20px] bg-Lightyellow rounded-[5px] text-white 
            text-[20px] hidden max_md:block cursor-pointer' onClick={()=>{
              if(path){
                navigate(path)
              }
              }}>View All</div>
          </>
        )}
      </div>

    </div>
  )
}

export default CommomProductComponent
