import React, { useState } from 'react'
import LOGOSVG from "../assets/images/Navbar/YogicLogo.svg";
import HambergerSVG from "../assets/images/Navbar/Hamberger.svg";
import {  useLocation, useNavigate } from 'react-router-dom';
import Dropdown from '../assets/images/Home/Dropdown.png';
import "../CSS/Navbar.css"


const Navbar = ({setIsMobileMenuOpen}) => {
  const navigate = useNavigate();

  const [MenuList] = useState([
    {
      name: "Product",
      link: "",
      subRoute: [
        {
          name: 'Cow Ghee',
          path: '/cow-ghee'
        },
        {
          name: 'Buffelo Ghee',
          path: '/buffelo-ghee'
        }
      ]
    },
    {
      name: "About",
      link: "/home",
    },
    {
      name: "Gallary",
      link: "/galary",
    },
    {
      name: "Contact",
      link: "/home",
    },

    
  ]);
  const location = useLocation();
  const [productOpen, setProductOpen] = useState(false)
  const scrollToSection = (id) => {
    var element = document.getElementById(id);  
    if(element){
      element.scrollIntoView({behavior:'smooth'});
    }
  }
  return (
    <div className='m-auto flex h-[80px] max-w-[1240px] items-center max_xl:max-w-[940px] 
    max_sm:h-[70px] z-40'>
      <div className='F-JC-AI-CENTER w-full justify-between max_md:justify-center'>
        <div className="absolute left-[40px] hidden max_md:block max_sm:left-[20px]">
          <img draggable="false"
            onClick={() => setIsMobileMenuOpen(true)}
            src={HambergerSVG}
            alt=""
          />
        </div>
        <div
          onClick={() => {
            if (location.pathname === "/") {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            } else {
              navigate("/");
            }
          }}
          className="F-JC-AI-CENTER cursor-pointer"
        >
          <div className="w-[140px] max_sm:w-[120px]">
            <img draggable="false" src={LOGOSVG} alt="" />
          </div>

        </div>
        <div className="F-JC-AI-CENTER flex gap-x-[50px] max_md:hidden">
          {MenuList.map((menu, index) => (
            <div
              key={index}
              onMouseEnter={() => {
                if (menu.name === "Product") {
                  setProductOpen(true);
                } else {
                  setProductOpen(false);
                }
              }}
              onMouseLeave={() => {
                if (menu.name === "Product") {
                  setProductOpen(false);
                }
              }}
              onClick={()=>{
                if(menu.name === "Gallary"){
                  navigate('/gallary')
                }
                if(menu.name === "Contact"){
                  navigate('/')
                  setTimeout(()=>{
                    scrollToSection("contact_us")
                  },500)
                }
                if(menu.name === "About"){
                  navigate('/')
                  setTimeout(()=>{
                    scrollToSection("about_ghee")
                  },500)
                }
                // if(menu.name === "Contact"){
                //   scrollToSection("ContactUs")
                // }
              }}
            >
              {/* <NavLink */}
              <div
                className="flex h-[50px] w-full items-center no-underline"
              // to={menu.link}
              >
                {/* {({ isActive }) => ( */}
                <>
                  <div
                    className={`abc cursor-pointer text-[20px] font-PoppinMedium uppercase text-blackText relative`}
                  >
                    {menu.name}
                  </div>
                  {menu.name === "Product" && (
                    <>
                      <div className='ml-2'><img src={Dropdown} alt='dp' /></div>
                    </>
                  )}
                  {productOpen && menu.name === "Product" && (
                    <>
                      <div className='absolute bg-white p-3 rounded-[10px] top-14 z-20 shadow-md'>
                        <div className='text-[20px] font-PoppinsRegular text-blackText hover:text-Lightyellow cursor-pointer' onClick={() => navigate('/cow-ghee')}>Cow Ghee</div>
                        <div className='text-[20px] font-PoppinsRegular text-blackText hover:text-Lightyellow cursor-pointer' onClick={() => navigate('/buffelo-ghee')}>Buffelo Ghee</div>
                      </div>
                    </>
                  )
                  }

                </>
                {/* )} */}
              </div>
            </div>
          ))}

        </div>
      </div>
    </div>
  )
}

export default Navbar
