import React, { useState } from 'react'
import LOGOSVG from "../assets/images/Navbar/YogicLogo.svg";
import CloseIcon from "../assets/images/Navbar/CloseIcon.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import Dropdown from '../assets/images/Home/Dropdown.png';



const MobileMenu = ({ setIsMobileMenuOpen, nodeRef, outsideRef }) => {
  const [openInnerMenu, setOpenInnerMenu] = useState(false)
  const location = useLocation();

  const navigate = useNavigate()
  const scrollToSection = (id) => {
    console.log("ID", id);
    var element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <>
      <div
        ref={nodeRef}
        className="z-90 fixed top-0 flex h-screen w-[100%] overflow-hidden z-50"
      >
        <div ref={outsideRef} className="w-full z-90 max-w-[330px] bg-white">
          <div
            style={{
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.03)",
            }}
            className="F-JC-AI-CENTER h-[70px] justify-between px-[20px]"
          >
            <div className="w-[120px]" onClick={()=>{
              if (location.pathname === "/") {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              } else {
                navigate("/");
              }
            }}>
              <img draggable="false" src={LOGOSVG} alt="" />
            </div>
            <div onClick={() => setIsMobileMenuOpen(false)}>
              <img draggable="false" src={CloseIcon} alt="" />
            </div>
          </div>
          <div className="z-90 h-[calc(100vh-70px)] z-100 overflow-y-scroll bg-white">
            <div className='text-[16px] font-PoppinMedium text-blackText px-5 pt-3' onClick={() =>{
              navigate('/')
              setIsMobileMenuOpen(false)
              setTimeout(()=>{
                scrollToSection("about_ghee")
              },500)
            }}>ABOUT</div>
            <div className='text-[16px] font-PoppinMedium text-blackText px-5 pt-3' onClick={() => navigate("/gallary")}>GALLARY</div>
            <div className='flex px-5 mt-5 gap-2 items-center'>

              <div className='text-[16px] font-PoppinMedium text-blackText '
                onClick={() => { setOpenInnerMenu(!openInnerMenu) }}
              >PRODUCT</div>
              <div className='ml-2'><img src={Dropdown} alt='dp' /></div>
            </div>
            {openInnerMenu && (
              <>
                <div className='text-[16px] font-PoppinsRegular text-blackText px-2 pt-3 ml-5' onClick={()=>navigate('/cow-ghee')}>Cow Ghee</div>
                <div className='text-[16px] font-PoppinsRegular text-blackText p-2 ml-5'onClick={()=>navigate('/buffelo-ghee')}>Buffelo Ghee</div>
              </>
            )}
            <div className='text-[16px] font-PoppinMedium text-blackText mx-5 mt-3' onClick={() => {
              setIsMobileMenuOpen(false)
              navigate("/")
              scrollToSection("contact_us")
              }}>CONTACT</div>
          </div>
        </div>

      </div>
    </>
  )
}

export default MobileMenu
