import React, { useRef, useState } from 'react'
import CowImg from '../assets/images/Home/1920_ Cow image.svg'
import ContactUsImg from '../assets/images/Home/YogicContactImg.png'
import GheeBowl from '../assets/images/Home/GheeBowl.png'
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  const [sent, setSent] = useState(false)
  const [error, setError] = useState("")

  const validateEmail = (email) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const regex = /^\d{10}$/; // Assuming a 10-digit phone number format
    return regex.test(phone);
  };


  const formRef = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const email = formRef.current['user_email'].value;
    const phone = formRef.current['user_mobile'].value;

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return; // Do not send the email if validation fails
    } else {
      setError(''); // Clear the error message if validation passes
    }

    if (!validatePhoneNumber(phone)) {
      setError('Please enter a valid 10-digit phone number.');
      return; // Do not send the email if validation fails
    } else {
      setError(''); // Clear the error message if validation passes
    }

    emailjs.sendForm('service_pgv006n', 'template_dv7hbar', formRef.current, 'gvfMlZdx8ISD7lnze')
      .then((result) => {
        if (result.status === 200) {
          setSent(true)
          console.log(result);

        }
      }, (error) => {
        console.log(error.text);
      });
    formRef.current.reset()
  };

  const mobileNumberRef = useRef();

  // Add an event listener to the mobile number input field
  const handleMobileNumberChange = (e) => {
    let mobileNumber = e.target.value;

    // Remove any non-digit characters from the input
    mobileNumber = mobileNumber.replace(/\D/g, '');

    // Limit the mobile number to 10 digits
    if (mobileNumber.length > 10) {
      mobileNumber = mobileNumber.slice(0, 10);
    }

    // Update the input value
    mobileNumberRef.current.value = mobileNumber;
  };

  return (
    <>
      <div className='relative w-[100%] my-[70px] F-JC-AI-CENTER'>
        <img src={CowImg} alt='Cow_img' id="ContactUs" />
        <div className='absolute border-2 bottom-24 invisible' id='contact_us'></div>
      </div>
      <div className='m-auto flex max-w-[1240px] items-center justify-center max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]'>
        <div className='flex w-full mb-[70px] max_768:flex-col max_768:gap-[30px] max_768:items-center'>
          <div className='w-[50%] max_md:w-[65%] max_720:w-[90%] max_720:rounded-[10px] h-[90%]'>
            <img src={ContactUsImg} alt='Yogic_img' className='w-full max_720:rounded-[10px] h-[90%]' />
          </div>
          <div className='w-[50%] max_md:w-[65%] bg-lightPink rounded-r-[10px] relative max_720:w-[90%] max_720:rounded-[10px]'>
            <form
              ref={formRef}
              onSubmit={(event) => sendEmail(event)}
            >

              <div className='relative px-[8%] max_sm:px-[6%] pt-[10%] pb-[8%] flex flex-col gap-5 max_xl:gap-[1rem] h-full justify-center 
                 max_xl:pt-[3%] max_xl:pb-[4%] max_md:pt-[3%] max_md:pb-[2%] max_sm:pb-[5%]'>
                <div className='headingT mb-[10px] max_xl:mb-[6px] max_md:text-[26px] max_md:mb-[2px]'>Contact us</div>
                <div className='inputF'>
                  <input placeholder='Name' name="user_name" className='inputField' style={{}} />
                </div>
                <div className='inputF'>
                  <input placeholder='Email' name='user_email' className='inputField' style={{}} />
                </div>
                <div className='inputF'>
                  <input placeholder='Mobile Number' name='user_mobile' className='inputField' ref={mobileNumberRef}
                    onChange={handleMobileNumberChange} />
                </div>
                <div className='inputF relative'>
                  <textarea placeholder='Message' name='user_message' className='inputField resize-none h-[155px] max_xl:h-[120px] max_md:h-[90px]' />
                  {error && (<>
                    <div className='absolute max_720:left-0 max_md:left-[10%] left-[20%] text-[14px] max_sm:text-[12px] font-PoppinsRegular text-red-500'>{error}</div>
                  </>)}
                </div>
                <div className='F-JC-AI-CENTER'>
                  {sent ? (<><div className='text-green text-[18px] text-[#008000] font-PoppinMedium py-[6px]'>Mail sent successfully!!</div></>) : (<>
                    <button className='w-[130px] F-JC-AI-CENTER py-[10px] px-[20px] bg-Lightyellow rounded-[5px] text-white 
                      text-[20px] cursor-pointer font-PoppinMedium max_md:py-[5px] max_xl:py-[5px]' type="submit">
                      Send
                    </button>
                  </>)}
                </div>

              </div>
            </form>
            <div className='absolute bottom-[0px] right-[-58px] max_sm:right-[-86px] max_smmm:right-[-106px]'>
              <img src={GheeBowl} alt='GheeBowl' className='max_sm:w-[55%] max_smmm:w-[46%]' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs
