import React, { useState } from 'react'
import Layout from '../utils/Layout'
import CommomProductComponent from '../CommomProductComponent'
import ml100 from '../../assets/images/Product/Cow/100C.png'
import ml200 from '../../assets/images/Product/Cow/200C.png'
import ml300 from '../../assets/images/Product/Cow/300C.png'
import ml500 from '../../assets/images/Product/Cow/500C.png'
import mlP500 from '../../assets/images/Product/Cow/500PC.png'
import l1 from '../../assets/images/Product/Cow/1C.png'
import lP1 from '../../assets/images/Product/Cow/1PC.png'
import l5 from '../../assets/images/Product/Cow/5PC.png'
import l15 from '../../assets/images/Product/GC3.png'
import Cow_BG from '../../assets/images/Product/Cow/BG_Cow.svg'

const CowProduct = () => {
  const [cowGheeData,] = useState([
    {
      img: ml100,
      weight: '100 ml'
    },
    {
      img: ml200,
      weight: '200 ml'
    },
    {
      img: ml300,
      weight: '300 ml'
    },
    {
      img: ml500,
      weight: '500 ml'
    },
    {
      img: mlP500,
      weight: '500 ml'
    },
    {
      img: l1,
      weight: '1 Ltr'
    },
    {
      img: lP1,
      weight: '1 Ltr'
    },
    {
      img: l5,
      weight: '5 Ltr'
    },
    {
      img: l15,
      weight: '15 Kg'
    },
  ])
  return (
    <Layout>
      <div className='m-auto flex flex-col max-w-[1240px] items-center justify-center 
      max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px] h-[100%] mt-[60px]'>

        <CommomProductComponent heading="Classic cow ghee" 
        subHeading="Introducing our classic cow ghee products made with real cow milk" 
        GheeData={cowGheeData} viewAll={false} />

        <div>
          <img src={Cow_BG} alt='Cow_bg' />
        </div>
      </div>

      
    </Layout>
  )
}

export default CowProduct
