import React from 'react'
import Webroutes from './Routes/Webroutes'
import ScrollToTop from './Components/utils/ScrollTop'

const App = () => {
  return (
    <div>
      <ScrollToTop />
      <Webroutes /> 
    </div>
  )
}

export default App
