import React, { useState } from 'react'
import One from '../../assets/images/Home/One.svg'
import Two from '../../assets/images/Home/Two.svg'
import Three from '../../assets/images/Home/Three.svg'

const FoodQuatos = () => {
  const [data] = useState([
    {
      text: 'Pure, fresh milk sourced directly from farmers',
      img: One,
    },
    {
      text: 'Hygienic processing & packaging to maintain quality',
      img: Two,
    },
    {
      text: 'Pure and Tasty dairy products on your table',
      img: Three,
    },
  ])


  return (
    <div className='bg-lightPink py-[70px]'>
      <div className='m-auto flex max-w-[1240px] items-center max_md:px-[50px] max_sm:px-[20px]'>
        <div className='F-JC-AI-CENTER flex-col w-[100%]'>
          <div className='text-[26px] max_md:text-[20px] font-PoppinsRegular text-blackText text-center'>
            At <b>YOGIC</b>,we believe that food should nourish the body, 
            mind and soul.</div>
          <div id='about_ghee' className='flex mt-[40px] gap-[30px] max_xl:flex-wrap max_xl:justify-center'>
            {data?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className='flex items-center'>
                    <div className='F-JC-AI-CENTER w-[130px] h-[130px] z-10 rounded-[50%] 
                    bg-white border-[1px] border-Lightyellow max_sm:w-[90px] max_sm:h-[90px]'>
                      <img src={item.img} alt='Ome' className='max_sm:p-4' />
                    </div>
                    <div className='flex items-center px-[22px] w-[212px] bg-white text-[16px] rounded-[20px] h-[106px]
                    border-[1px] border-Lightyellow ml-[-16px] font-PoppinMedium max_sm:w-[200px] max_sm:text-[14px]'>
                    {item.text}
                    </div>
                  </div>
                </React.Fragment>
              )
            })}

            {/* <div className='flex'>
              <div className='F-JC-AI-CENTER w-[130px] h-[130px] rounded-[50%]'>
                <img src={Two} alt='Ome' />
              </div>
              <div className='w-[212px]'>

              </div>
            </div>
            <div className='flex'>
              <div className='F-JC-AI-CENTER w-[130px] h-[130px] rounded-[50%]'>
                <img src={Three} alt='Ome' />
              </div>
              <div className='w-[212px]'>

              </div>
            </div> */}
          </div>
        </div>

      </div>
    </div>
  )
}

export default FoodQuatos
