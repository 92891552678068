import React from 'react'
import Layout from '../utils/Layout'
import HomeBackDesk from '../../assets/images/Home/Group 63.svg'
import HomeBackMobile2 from '../../assets/images/Home/BG (6).png'
import HomeBackMobile3 from '../../assets/images/Home/BG (7).png'
import HomePageGhee from '../../assets/images/Home/HomepageGhee.png'
import Vector from '../../assets/images/Home/Vector (4).png'
import HomeText from '../../assets/images/Home/HomeText.svg'
import FoodQuatos from './FoodQuatos'
import AboutGhee from '../AboutGhee'
import ProductHome from '../ProductHome'
import GheeMakingProcess from '../GheeMakingProcess'
import ContactUs from '../ContactUs'
import Stars from "../../assets/images/Home/Stars.png"
import Pure from "../../assets/images/Home/pure.png"
import "../CSS/Home.css"

const Home = () => {
  return (
    <>
      <Layout>
        <div className='relative h-[calc(100vh-80px)] max_smm:h-[calc(100vh-200px)]'>

          <div className='m-auto flex max-w-[1240px] items-center justify-center max_xl:max-w-[940px] max_md:px-[50px] 
            max_sm:px-[20px] h-[100%]'>
            <div className='F-JC-AI-CENTER flex-col'>
              <div className='max_sm:z-[-2]'>
                <img src={HomeText} loading="lazy" alt='Text_ghee' />
              </div>
              <div className='flex w-[75%] mt-[-40px] max_sm:mt-[-20px] max_smm:mt-[-5px]'>
                <div className='max_sm:z-[-2] '>
                  <img src={Stars} alt='Stars' />
                </div>
                <div className='ml-6 z-[-2]'>
                  <img src={Pure} alt='Stars' />
                </div>
              </div>
              <div className='max_sm:z-[-2] mt-[-60px] max_md:mt-[-35px] 
                max_720:mt-[-25px] max_sm:mt-[-10px]'>
                <img src={HomePageGhee} loading="lazy" alt='Ghee_image' />
               
              </div>
            </div>

          </div>
          <div className='w-full absolute top-0 left-0 z-[-5] hidden max_smm:block max_mesm:hidden'>
            <img src={HomeBackMobile2} alt='Home' loading="lazy" />
          </div>
          <div className='w-full absolute top-0 left-0 z-[-5] hidden max_smm:hidden max_mesm:block'>
            <img src={HomeBackMobile3} alt='Home' loading="lazy" />
          </div>
          <div className='max_smm:hidden max_mesm:hidden max_768:block hidden w-full absolute top-0 left-0 z-[-5] '>
            <img src={Vector} alt='Home' loading="lazy" />
          </div>
          <div className='max_720:hidden block w-full absolute top-0 left-0 z-[-5] '>
            <img src={HomeBackDesk} alt='Home' loading="lazy" />
          </div>

        </div>

        <FoodQuatos />

        <AboutGhee />

        <ProductHome />

        <GheeMakingProcess />

        <ContactUs />
      </Layout>
    </>
  )
}

export default Home
