import React, { useState } from 'react'
import Layout from '../utils/Layout'
import CommomProductComponent from '../CommomProductComponent'
import ml100 from "../../assets/images/Product/Buffelo/100B.png"
import ml200 from "../../assets/images/Product/Buffelo/200B.png"
import ml300 from "../../assets/images/Product/Buffelo/300B.png"
import ml500 from "../../assets/images/Product/Buffelo/500B.png"
import l1 from "../../assets/images/Product/Buffelo/1B.png"
import l5 from "../../assets/images/Product/Buffelo/5B.png"
import l15 from "../../assets/images/Product/Buffelo/15B.png"
import buffelo_bg from "../../assets/images/Product/Buffelo/BG_Buffelo.svg"

const BuffeloProduct = () => {
  const [buffeloGhee,] = useState([
    {
      img:ml100,
      weight:'100 ml'
    },
    {
      img:ml200,
      weight:'200 ml'
    },
    {
      img:ml300,
      weight:'300 ml'
    },
    {
      img:ml500,
      weight:'500 ml'
    },
    {
      img:l1,
      weight:'1 Ltr'
    },
    {
      img:l5,
      weight:'5 Ltr'
    },
    {
      img:l15,
      weight:'15 kg'
    },
  ])
  return (
    <Layout>
      <div className='m-auto flex flex-col max-w-[1240px] items-center justify-center 
      max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px] h-[100%] mt-[60px]'>

        <CommomProductComponent heading="Premium buffalo ghee"
          subHeading="Introducing our premium buffalo ghee products made with real buffalo milk"
          GheeData={buffeloGhee} viewAll={false} />

        <div>
          <img src={buffelo_bg} alt='Cow_bg' />
        </div>
      </div>
    </Layout>
  )
}

export default BuffeloProduct
