import React, { useState } from 'react'
import HealthyGhee from '../assets/images/Home/AboutGhee.png';
import Check from '../assets/images/Home/Check.svg';
import Quality from '../assets/images/Home/QualityIcon.svg';
import CowIcon from '../assets/images/Home/CowIcon.svg';
import HygenicIcon from '../assets/images/Home/HygenicIcon.svg';
import Dropdown from '../assets/images/Home/Dropdown.png';
import Ghee1 from '../assets/images/Home/Ghee1.png';
import BACK from '../assets/images/Home/HomePageBack.png';

const AboutGhee = () => {
  const [openMore, setOpenMore] = useState(false)
  const [data,] = useState([
    {
      title: '100% Pure & Natural'
    },
    {
      title: 'Rich Taste and Aroma'
    },
    {
      title: 'Granular Texture'
    },
  ])
  const [details,] = useState([
    {
      img: HygenicIcon,
      name: '100% Hygienic'
    },
    {
      img: CowIcon,
      name: 'Best Practices'
    },
    {
      img: Quality,
      name: 'Stringent Quality Standards'
    },
  ])
  const [gheeDetails,] = useState([
    "Ghee is all Natural",
    "Ghee reduces your exposure to cancer-causing agents.",
    "Ghee is a rich source of antioxidants.",
    "Ghee contains cancer-fighting CLA.",
    "Ghee helps moisturize dry skin and hair.",
    "Ghee has anti-inflammatory properties.",
    "Ghee contains heart-healthy fats.",
    "Ghee is a viable alternative for individuals with dairy allergies.",
    "Ghee is a nutritional powerhouse."
  ])
  return (
    <>
      <div className='relative'>
        <div className='m-auto max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px] max_sm:flex max_sm:flex-col max_sm:items-center'>
          <div className='flex justify-center items-center gap-[60px] my-[50px] max_md:flex-col'>
            <div className='w-[50%] max_md:w-[70%] max_md:flex max_md:justify-center max_sm:w-[85%]'>
              <img src={HealthyGhee} alt='About_ghee' style={{ borderRadius: '10px' }} />
            </div>
            <div className='w-[50%] max_md:F-JC-AI-CENTER max_md:flex-col max_md:w-[70%] max_sm:w-[85%]'>
              <div className='headingT max_sm:text-[30px]'>About Ghee</div>
              <div className='my-[30px] text-[18px] font-PoppinMedium max_md:text-center'>Yogic GHEE is made from fresh cream it has a typical rich aroma and a granular texture, Ghee is an ethnic product made by dairies
                with decades of experience, and is a rich source of vitamin "A".</div>
              <div className='flex flex-col' >
                {data?.map((item, index3) => {
                  return (
                    <React.Fragment key={index3}>
                      <div className='flex items-center gap-2 font-PoppinSemibold mb-[15px]'>
                        <div><img src={Check} alt='Check' /></div>
                        <div>{item.title}</div>
                      </div>
                    </React.Fragment>
                  )

                })}

              </div>
              <div className='flex mt-[15px] w-[100%] max_md:justify-center max_sm:flex-wrap max_sm:items-center 
              max_sm:gap-3 max_720:gap-3'>
                {details.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className='flex flex-col items-center w-[25%] max_720:w-[30%] max_sm:w-[40%]'>
                        <div className='flex '>
                          <img src={item.img} alt='Ghee' />
                        </div>
                        <div className='mt-[20px] font-PoppinMedium text-[16px] text-center max_sm:mt-1'>
                          {item.name}
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}

              </div>
            </div>
          </div>


          {openMore && (
            <>
              <div className='F-JC-AI-CENTER flex-col gap-[40px] max_sm:w-[85%]'>
                <div className='text-blackText'>
                  <div className='F-JC-AI-CENTER headingT max_sm:text-[30px]'>Vision</div>
                  <div className='text-[18px] font-PoppinMedium text-center mt-2'>
                    Being a client oriented organization, we lay huge importance on achieving comprehensive customers
                    gratification in order to uphold our reputed position in the market. To complete the entire donor
                    satisfaction, we ensure that offered products are outstanding in quality. We provide our products
                    in many specifications and packing facilities to accomplish the precise demands of the customers.
                    Apart from this, we organize our products under clean & hygienic atmosphere to provide the optimum quality assortment of products.
                  </div>
                </div>
                <div className='text-blackText mt-2'>
                  <div className='F-JC-AI-CENTER headingT'>Why us</div>
                  <div className='text-[18px] font-PoppinMedium text-center mt-2'>
                    We at Yogic Desi mart believe in offering our customers with supreme quality food
                    products at in-expensive rates. Owing to the transparent business dealings and
                    patron centric approaches, we have been able to attain the confidence of our valued
                    customers in the most effectual way. The inexpensive pricing and safe packing, has
                    assisted us in attaining an exceptional development in such short period.
                  </div>
                </div>

                <div className='text-greenText font-MargaFont text-[48px]'>
                  Yogic Ghee
                </div>

                <div className='F-JC-AI-CENTER w-full z-30'>
                  <div className='F-JC-AI-CENTER w-full relative flex-col'>
                    <div className='text-[18px] text-blackText font-PoppinMedium mb-6 max_md:hidden'>Improves energy levels</div>
                    <div className='w-[490px] flex flex-col items-center max_sm:w-[320px] max_z:w-[285px]'>
                      <div className='z-10 max_sm:w-[70%] max_sm:z-[-10]'>
                        <img src={Ghee1} alt='Ghee' />
                      </div>
                      <div className='w-full bg-greenBackground rounded-t-[70px] mt-[-126px] max_sm:mt-[-98px] 
                      max_sm:rounded-t-[50px] max_sm:z-[-20]'>
                        <div className='flex flex-col px-[40px] pt-[140px] pb-[40px] max_sm:pb-[20px] max_sm:px-[20px] max_sm:pt-[100px]'>
                          {gheeDetails.map((item,index1) => {
                            return (
                              <React.Fragment key={index1}>
                                <div className='flex gap-1 '>
                                  <div className='flex text-start'>
                                  <div className='w-[6px] h-[6px] rounded-full bg-white mt-[10px]'></div>
                                  </div>
                                  <div className='text-[16px] text-white font-PoppinsRegular'>{item}</div>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </div>
                    </div>


                    <div className='absoluteRound top-[16%] left-[22%] max_xl:left-[12%]  max-w-[155px]  max_md:hidden '>Rich in <br />fat soluble <br />vitamins A, D & E</div>
                    <div className='absoluteRound top-[16%] right-[22%] max_xl:right-[12%] max-w-[150px] max_md:hidden '>builds strong bones with vitamin k2</div>
                    <div className='absoluteRound top-[36%] left-[15%] max_xl:left-[5%] max-w-[136px] max_md:hidden '>Aids in overall <br />brain health</div>
                    <div className='absoluteRound top-[36%] right-[15%] max_xl:right-[5%] max-w-[126px] max_md:hidden '>Improves digestion</div>
                  </div>
                </div>
              </div>
            </>
          )}


          <div className='mt-[40px] z-10'>
            <div className='F-JC-AI-CENTER'>
              <div className='flex items-center gap-3 p-2 cursor-pointer z-10' onClick={() => setOpenMore(!openMore)} id='Prod'>
                <p className='text-[18px] font-PoppinMedium '>{!openMore ? "View More" : "View Less"}</p>
                <div className={`${!openMore ? "rotate-[360deg]" : "rotate-180"
                  } transition-all`}><img src={Dropdown} alt='dp' /></div>
              </div>
            </div>



          </div>
        </div>
        {openMore && (<>
          <div className='absolute bottom-0'>
            <img src={BACK} alt='Back' />
          </div>
        </>)}

      </div>
    </>

  )
}

export default AboutGhee
