import React from 'react'
import Layout from '../utils/Layout'
import brochure from '../../assets/images/Gallary/brochure.png'
import b1 from '../../assets/images/Gallary/Mask group.png'
import b2 from '../../assets/images/Gallary/Mask group (1).png'
import b3 from '../../assets/images/Gallary/Mask group (2).png'
import b4 from '../../assets/images/Gallary/Mask group (3).png'
import ExamplePdf from "../../Components/utils/Pdf/YogicBrochure.pdf"


const Gallary = () => {
  const brochures = [b1, b2, b3, b4]
  return (
    <>
      <Layout>
        <div className='m-auto max-w-[1240px] max_xl:max-w-[940px] my-[20px] max_md:px-[50px] max_sm:px-[20px]'>
          <div className='w-full flex flex-col mt-[70px]'>
            <div className='w-full flex justify-center gap-14 max_720:flex-col max_720:items-center'>
              <div className='w-[50%] flex justify-end max_720:w-[80%]'>
                <div className=''>
                  <img src={brochure} alt='brochure' /></div>
              </div>
              <div className='w-[50%] flex justify-start items-center max_720:w-[80%] max_720:justify-center'>
                <div className='flex-col justify-center w-[50%] max_xl:w-[70%] max_720:w-[90%] max_720:items-center'>

                  <div className='text-[36px] font-PoppinSemibold text-blackText max_sm:text-center'>BROCHURE</div>
                  <div className='text-[20px] font-PoppinBold text-blackText mt-[10px] max_sm:text-center'>Yogic Ghee</div>
                  <div className='text-[20px] font-PoppinsRegular max_sm:text-center'>that’s good for your health.</div>
                  <div className='text-[20px] font-PoppinsRegular mt-[8%] max_sm:text-center'>A nutrient packed twist
                    to your regular meal!
                  </div>
                  <a
                    href={ExamplePdf}
                    download="Yogic_Brochure"
                    target="_blank"
                    rel="noreferrer"
                  >

                    <div className='mt-[5%] max-w-[260px] 
                rounded-[10px] bg-Lightyellow px-[60px] py-[20px] text-[20px] font-PoppinBold text-white
                text-center
                cursor-pointer
                '>Download</div>
                  </a>
                </div>
              </div>

            </div>
          </div>

          <div className='w-full flex flex-wrap justify-between mb-[60px] gap-2 max_720:flex-col max_720:items-center'>
            {brochures.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className='w-[48%] flex justify-center mt-[70px] max_720:w-[78%] '>
                    <img src={item} alt='B1' className='border-[1px] border-Lightyellow rounded-[10px]' />
                  </div>

                </React.Fragment>
              )
            })}
          </div>
        </div>

      </Layout >
    </>
  )
}

export default Gallary
